import UserConfigurationService from './UserConfigurationService';

export enum SiteScannerUiToggles {
	ENABLE_PROPERTIES_JSON_EDITOR = 'ENABLE_PROPERTIES_JSON_EDITOR',
	ENABLE_SCAN_URLSET_JSON_CONFIG = 'ENABLE_SCAN_URLSET_JSON_CONFIG',
	ENABLE_CRAWL_URLSET_JSON_CONFIG = 'ENABLE_CRAWL_URLSET_JSON_CONFIG',
	ENABLE_SCAN_OVERVIEW_PRINT_BUTTON = 'ENABLE_SCAN_OVERVIEW_PRINT_BUTTON',
	ENABLE_CRAWL_FILE_TYPES_OPTION = 'ENABLE_CRAWL_FILE_TYPES_OPTION',
	ENABLE_COMPARE_SCANS = 'ENABLE_COMPARE_SCANS',
	ENABLE_AZURE_INTEGRATION = 'ENABLE_AZURE_INTEGRATION',
	ENABLE_COOKIES_CONSENT_SELECTOR = 'ENABLE_COOKIES_CONSENT_SELECTOR',
	ENABLE_EVINCED_USAGE_ANALYTICS = 'ENABLE_EVINCED_USAGE_ANALYTICS',
	ENABLE_SCAN_OVERVIEW_V3 = 'ENABLE_SCAN_OVERVIEW_V3',
	ENABLE_EVINCED_ADVANTAGE_DIAGRAM = 'ENABLE_EVINCED_ADVANTAGE_DIAGRAM'
}
export enum DevelopmentToggles {
	PROPERTY_JSON_EDITOR = 'PROPERTY_JSON_EDITOR',
	SHOW_SCAN_URLSET_JSON_CONFIG = 'SHOW_SCAN_URLSET_JSON_CONFIG',
	SHOW_URLSET_JSON_CONFIG = 'SHOW_URLSET_JSON_CONFIG',
	SHOW_PRINT_BUTTON = 'SHOW_PRINT_BUTTON',
	SHOW_CRAWL_FILE_TYPES = 'SHOW_CRAWL_FILE_TYPES',
	SHOW_COMPARE_SCANS = 'SHOW_COMPARE_SCANS',
	ENABLE_AZURE_INTEGRATION = 'ENABLE_AZURE_INTEGRATION',
	SHOW_COOKIES_CONSENT_SELECTOR = 'SHOW_COOKIES_CONSENT_SELECTOR',
	SEND_USAGE_ANALYTICS = 'SEND_USAGE_ANALYTICS',
	SHOW_SCAN_OVERVIEW_V3 = 'SHOW_SCAN_OVERVIEW_V3',
	SHOW_EVINCED_ADVANTAGE_DIAGRAM = 'SHOW_EVINCED_ADVANTAGE_DIAGRAM'
}

const NEW_TO_OLD_KEYS_MAP = [
	{
		new: SiteScannerUiToggles.ENABLE_PROPERTIES_JSON_EDITOR,
		old: DevelopmentToggles.PROPERTY_JSON_EDITOR
	},
	{
		new: SiteScannerUiToggles.ENABLE_SCAN_URLSET_JSON_CONFIG,
		old: DevelopmentToggles.SHOW_SCAN_URLSET_JSON_CONFIG
	},
	{
		new: SiteScannerUiToggles.ENABLE_CRAWL_URLSET_JSON_CONFIG,
		old: DevelopmentToggles.SHOW_URLSET_JSON_CONFIG
	},
	{
		new: SiteScannerUiToggles.ENABLE_SCAN_OVERVIEW_PRINT_BUTTON,
		old: DevelopmentToggles.SHOW_PRINT_BUTTON
	},
	{
		new: SiteScannerUiToggles.ENABLE_CRAWL_FILE_TYPES_OPTION,
		old: DevelopmentToggles.SHOW_CRAWL_FILE_TYPES
	},
	{ new: SiteScannerUiToggles.ENABLE_COMPARE_SCANS, old: DevelopmentToggles.SHOW_COMPARE_SCANS },
	{
		new: SiteScannerUiToggles.ENABLE_AZURE_INTEGRATION,
		old: DevelopmentToggles.ENABLE_AZURE_INTEGRATION
	},
	{
		new: SiteScannerUiToggles.ENABLE_COOKIES_CONSENT_SELECTOR,
		old: DevelopmentToggles.SHOW_COOKIES_CONSENT_SELECTOR
	},
	{
		new: SiteScannerUiToggles.ENABLE_EVINCED_USAGE_ANALYTICS,
		old: DevelopmentToggles.SEND_USAGE_ANALYTICS
	},
	{
		new: SiteScannerUiToggles.ENABLE_SCAN_OVERVIEW_V3,
		old: DevelopmentToggles.SHOW_SCAN_OVERVIEW_V3
	},
	{
		new: SiteScannerUiToggles.ENABLE_EVINCED_ADVANTAGE_DIAGRAM,
		old: DevelopmentToggles.SHOW_EVINCED_ADVANTAGE_DIAGRAM
	}
];

// todo: to make it not take values from auth0 - turn `includeUserToggles` to false
const getToggle = (toggle: SiteScannerUiToggles, useAuth0Toggles = true): boolean => {
	const oldAuth0ToggleName = NEW_TO_OLD_KEYS_MAP.find((key) => key.new === toggle)?.old;
	const value = localStorage.getItem(toggle) || localStorage.getItem(oldAuth0ToggleName);

	// cast the string value to it's boolean equivilant
	const devToggle = value === 'true';
	// UserCOnfigurationService.getUserToggles() gets the auth0 value
	return (
		devToggle || (useAuth0Toggles && UserConfigurationService.getUserToggles()[oldAuth0ToggleName])
	);
};

const setToggle = (toggle: SiteScannerUiToggles, value: string): void => {
	localStorage.setItem(toggle, value);
};

const removeToggle = (toggle: SiteScannerUiToggles): void => {
	localStorage.removeItem(toggle);
};

export default { getToggle, setToggle, removeToggle };
