import React, { FC, useMemo } from 'react';

import {
	BarDatum,
	EvBarChart,
	EvChartBottomTick,
	EvChartTooltip,
	FormatHelper,
	PatternData
} from '@evinced-private/ui-common';

import { EVINCED_SEVERITIES_BY_ID } from '../../../consts/severityIDs';
import ColorsHelper from '../../../helpers/charts/ColorsHelper';
import IdFormatterHelper from '../../../helpers/IdFormatterHelper';
import MathHelper from '../../../helpers/MathHelper';
import { SeverityData } from '../../../types/OverviewTypes';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';

interface IPagesBySeverityBarProps {
	onBarItemClick: (stateKey: string, stateValue: string | number) => void;
	pagesScanned: number;
	chartHeight?: number;
	chartWidth?: number;
	fitWidthToContent?: boolean;
	pagesSeverities?: SeverityData[];
}

const COLUMN_WIDTH_IN_PIXELS = 45;
const PADDING_BETWEEN_COLUMNS_IN_PIXELS = 30;
const DEFAULT_CHART_HEIGHT = 410;
const DEFAULT_CHART_WIDTH = 501;

const PagesBySeverityBar: FC<IPagesBySeverityBarProps> = ({
	pagesSeverities,
	pagesScanned,
	chartHeight = DEFAULT_CHART_HEIGHT,
	fitWidthToContent = false,
	chartWidth = DEFAULT_CHART_WIDTH,
	onBarItemClick
}) => {
	const barData = useMemo((): BarDatum[] => {
		return pagesSeverities?.map(({ pagesCount, severity }) => {
			const percentOfPagesWithSeverity = MathHelper.calcPercentage(pagesCount, pagesScanned);
			return {
				id: IdFormatterHelper.formatTextToId(severity),
				label: severity,
				percent: percentOfPagesWithSeverity
			};
		});
	}, [pagesSeverities, pagesScanned]);

	const patterns = useMemo(() => {
		return barData.map(
			({ id, label }) =>
				({
					id,
					label,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_ID[id]]
				}) as PatternData
		);
	}, [barData]);

	const tooltipRenderer = (d): JSX.Element => {
		return <EvChartTooltip label={d.id} value={`${d.value}%`} />;
	};

	const calculateChartWidth = (barData: BarDatum[]): number => {
		const numberOfCols = barData.length;
		const totalWidthOfCols = numberOfCols * COLUMN_WIDTH_IN_PIXELS;
		// the chart has padding after each column + padding before the first (columns + 1)
		const totalWidthWithoutCols = (numberOfCols + 1) * PADDING_BETWEEN_COLUMNS_IN_PIXELS;
		const widthOfAxisTicks = 50;
		return totalWidthOfCols + totalWidthWithoutCols + widthOfAxisTicks;
	};

	const width = useMemo(() => {
		if (!fitWidthToContent) {
			return chartWidth;
		}
		return calculateChartWidth(barData);
	}, [fitWidthToContent, barData, chartWidth]);

	const srSummaryData = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					count: `${bar.percent}%`
				}) as PatternData
		);
	}, [barData]);

	const severityFieldFormatter = (cell: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onBarItemClick('severity', cell)} value={cell} />;
	};

	const chartTitle = 'Pages impacted by issue severity';
	return (
		<EvBarChart
			title={chartTitle}
			className="pages-by-severity"
			width={width}
			height={chartHeight}
			data={barData}
			patterns={patterns}
			srSummaryData={srSummaryData}
			keys={['percent']}
			indexBy="label"
			onBarClick={(barData) => {
				onBarItemClick('severity', barData.data.label);
			}}
			isFocusable
			withLegend={false}
			tableProps={{
				dataType: 'Severity',
				columnsPattern: [
					{ name: 'Severity', field: 'label', formatter: severityFieldFormatter },
					{ name: 'Issues Percentage', field: 'percent' }
				]
			}}
			barDesignProps={{
				margin: { top: 20, right: 0, bottom: 40, left: 50 },
				enableLabel: false,
				tooltip: tooltipRenderer,
				gridYValues: [0, 25, 50, 75, 100],
				padding: 0.5,
				animate: false,
				axisTop: null,
				axisRight: null,
				axisBottom: {
					tickSize: 0,
					tickPadding: 20,
					renderTick: (tick) => <EvChartBottomTick tick={tick} maxLineLength={10} />
				},
				axisLeft: {
					tickSize: 0,
					tickValues: [0, 25, 50, 75, 100],
					tickPadding: 10,
					format: FormatHelper.addPercentSign
				}
			}}
			headerTooltipProps={{
				tooltipText:
					'The graph indicates the distribution of issues across pages. Each vertical graph represents the % of impacted (has issues) pages by issue severity. For example in this graph, more than 50% of the pages contains critical issues.',
				tooltipTitle: `What is ${chartTitle} severity`,
				tooltipPlacement: 'right'
			}}
		/>
	);
};

export default PagesBySeverityBar;
