import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { compact, omitBy, pick } from 'lodash';

import {
	EvIssueFormModal,
	EvSpinner,
	FieldType,
	INPUT_TYPES,
	OptionType
} from '@evinced-private/ui-common';

import JiraApi from '../../api/JiraApi';
import { SITE_SCANNER_APP_ID } from '../../consts/dom-consts';
import { asyncHandler } from '../../helpers/AsyncHelper';
import logger from '../../services/Logger';
import { ScreenshotService } from '../../services/ScreenshotService';
import {
	IAttachmentAdded,
	ICreatedIssue,
	IIssueMeta,
	IProjectsMetaResponse,
	IProjectValue,
	JiraCloudData
} from '../../types/JiraTypes';
import { Report } from '../../types/ReportModel';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';

import { adjustOptions } from './helpers/JiraDropdownOptionsHelper';
import { formatProjectsData, initializeIssueTypesOptions } from './helpers/ProjectsMetaHelper';

import './JiraIssueCreator.scss';

const alertPopupTitle = 'Connect to Jira';
const issueFormTitle = 'Create Issue in Jira';

const fieldsOrder = [
	'Project',
	'Type',
	'Summary',
	'Components',
	'Description',
	'Reporter',
	'Assignee',
	'Priority',
	'Labels',
	'Fix versions',
	'Attachment'
];
const supportedFields = [
	'Assignee',
	'Reporter',
	'Components',
	'Priority',
	'Labels',
	'Fix versions'
];
const selectTypes = ['user', 'priority', 'array'];

interface IJiraIssueCreator {
	isOpen: boolean;
	closeModal: () => void;
	toggleJiraModal: (showModal: boolean) => void;
	ticketData: Report;
}

const JiraIssueCreator: FC<IJiraIssueCreator> = ({
	closeModal,
	isOpen,
	toggleJiraModal,
	ticketData
}) => {
	const [issueMeta, setIssueMeta] = useState(null);
	const [selectedProjectId, setSelectedProjectId] = useState(null);
	const [selectedIssueTypeId, setSelectedIssueTypeId] = useState(null);
	const [issueFields, setIssueFields] = useState(new Map());
	const [alertModalOpen, setAlertModalOpen] = useState(false);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const getAndSaveJiraData = async (): Promise<void> => {
		const [data] = await asyncHandler<[JiraCloudData]>(JiraApi.getJiraCloudId());
		const jiraData = data?.[0];
		if (jiraData) {
			JiraApi.setJiraData(jiraData.id, jiraData.url);
			setLoggedIn(true);
			setAlertModalOpen(false);
		} else {
			setLoggedIn(false);
			setAlertModalOpen(true);
		}
	};

	useEffect(() => {
		getAndSaveJiraData();
	}, []);

	const callApi = useCallback(
		async (key, url, search = '', setOptions): Promise<void> => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const [response] = await asyncHandler<any>(JiraApi.getAutocompleteValues(url + search));
			if (response) {
				const formattedOptions = adjustOptions(response);
				if (setOptions) {
					setOptions(formattedOptions);
				}
				setIssueFields((prevState) => {
					const cloneState = new Map(prevState);
					const prevValue = cloneState.get(key);
					return cloneState.set(key, {
						...prevValue,
						options: formattedOptions
					});
				});
			}
		},
		[setIssueFields]
	);

	const getIssueMeta = async (selectedProjectId: string, selectedIssueTypeId): Promise<void> => {
		const [issueMetaData] = await asyncHandler<IIssueMeta>(
			JiraApi.getIssueMeta(selectedProjectId, selectedIssueTypeId)
		);
		const issueMetaFields = issueMetaData?.fields;
		if (issueMetaFields) {
			setIssueMeta(issueMetaFields);
		}
	};

	const resetFields = useCallback(async () => {
		if (ticketData) {
			const boundingBoxes = ticketData.elements.map((element) => element.boundingBox);
			const [screenshotImageData] = await asyncHandler(
				ScreenshotService.getFullPageScreenshotWithIssues({
					pageScreenshotUrl: ticketData.pageScreenshot,
					boundingBoxes
				})
			);
			const summaryData = {
				key: 'summary',
				name: 'Summary',
				type: 'string',
				value: ticketData.summary || 'Untitled Bug',
				required: true
			};
			const descData = {
				key: 'description',
				name: 'Description',
				type: 'textarea',
				value: ticketData.description,
				required: false
			};
			const screenshotData = {
				key: 'attachment',
				name: 'Attachment',
				type: 'attachment',
				value: screenshotImageData,
				required: false
			};

			setIssueFields((prevState) => {
				const cloneIssueFields = new Map(prevState);
				cloneIssueFields.set(summaryData.key, summaryData);
				cloneIssueFields.set(descData.key, descData);
				if (screenshotImageData) {
					cloneIssueFields.set(screenshotData.key, screenshotData);
				}
				return cloneIssueFields;
			});
			setLoading(false);
		}
	}, [ticketData]);

	useEffect(() => {
		resetFields();
	}, [resetFields]);

	const onProjectChange = async (data: IProjectValue): Promise<void> => {
		const isNew = selectedProjectId !== data.value;
		let newProjectData = { ...data };
		if (!data?.issuetypes) {
			const projectWithIssueTypes = await JiraApi.getProjectIssueTypes(data.value);
			const issueTypes = initializeIssueTypesOptions(projectWithIssueTypes.issueTypes);
			newProjectData = { ...newProjectData, issuetypes: issueTypes };
		}

		// TODO: we currently don't support subtasks issue types, as it requires
		// more complex flow of setting the parent.
		if (isNew) {
			const issueTypes = newProjectData.issuetypes.filter((type) => !type.subtask);
			setIssueFields((prevState) => {
				const projectData = prevState.get('project');
				const typeData = prevState.get('issuetype');
				const cloneState = new Map();
				// new fields depend on project and type ids
				cloneState.set('project', { ...projectData, value: newProjectData });
				cloneState.set('issuetype', { ...typeData, options: issueTypes, value: issueTypes[0] });
				return cloneState;
			});
			setSelectedProjectId(newProjectData.value);
			setSelectedIssueTypeId(issueTypes[0].id);
			// after setting the project and type we need to reset the form
			resetFields();
			getIssueMeta(newProjectData.value, issueTypes[0].id);
		}
	};

	const onIssueTypeChange = (data): void => {
		const isNew = selectedIssueTypeId !== data.value;
		if (isNew) {
			const cloneIssueFields = new Map(issueFields);
			setIssueFields(cloneIssueFields);
			setIssueFields((prevState) => {
				const projectData = prevState.get('project');
				const typeData = prevState.get('issuetype');
				const cloneState = new Map();
				// new fields depend on project and type ids
				cloneState.set('issuetype', { ...typeData, value: data });
				cloneState.set('project', projectData);
				return cloneState;
			});
			setSelectedIssueTypeId(data.value);
			// after setting the type we need to reset the form
			resetFields();
			getIssueMeta(selectedProjectId, data.value);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const setIssueFieldsData = (key: string, value: any): void => {
		if (key === 'project') {
			onProjectChange(value);
			return;
		}
		if (key === 'issuetype') {
			onIssueTypeChange(value);
			return;
		}
		setIssueFields((prevState) => {
			const cloneState = new Map(prevState);
			const prevValue = cloneState.get(key);
			return cloneState.set(key, {
				...prevValue,
				value
			});
		});
	};

	const setProjectsMeta = useCallback(async (projects): Promise<void> => {
		const [projectsData, issueTypesData] = await formatProjectsData(projects);
		setSelectedProjectId((projectsData.value as OptionType).value);
		setSelectedIssueTypeId((issueTypesData.value as OptionType).value);
		setIssueFields((prevState) => {
			const cloneIssueFields = new Map(prevState);
			cloneIssueFields.set(projectsData.key, projectsData);
			cloneIssueFields.set(issueTypesData.key, issueTypesData);
			return cloneIssueFields;
		});
	}, []);

	const initApi = useCallback(async (): Promise<void> => {
		const [projects, error] = await asyncHandler<IProjectsMetaResponse>(JiraApi.getProjectsMeta());
		if (projects) {
			await setProjectsMeta(projects);
		}
		if (error) {
			logger.error('Error getting projects meta:', error);
		}
	}, [setProjectsMeta]);

	useEffect(() => {
		if (!alertModalOpen && isLoggedIn) {
			initApi();
		}
	}, [alertModalOpen, isLoggedIn, initApi]);

	useEffect(() => {
		if (issueMeta) {
			const cloneIssueFields = new Map(issueFields);
			const fields = omitBy(issueMeta, (field) =>
				['project', 'issuetype', 'summary', 'description'].includes(field.key)
			);
			Object.keys(fields).forEach((fieldKey) => {
				const field = issueMeta[fieldKey];
				const fieldType = selectTypes.includes(field.schema.type) ? 'select' : field.schema.type;
				const isEmpty =
					!field.required && fieldType === 'select' && field.allowedValues?.length === 0;

				if ((supportedFields.includes(field.name) || field.schema.type === 'string') && !isEmpty) {
					const fieldData = {
						key: field.key,
						name: field.name,
						type: fieldType,
						schema: field.schema,
						required: field.required,
						options: adjustOptions(field.allowedValues),
						value:
							field.hasDefaultValue && field.defaultValue
								? { value: field.defaultValue?.id, label: field.defaultValue?.name }
								: null,
						isMultiple: field.operations?.includes('add'),
						callApi: field.autoCompleteUrl
							? (searchStr, cb) => callApi(field.key, field.autoCompleteUrl, searchStr, cb)
							: null
					};
					cloneIssueFields.set(field.key, fieldData);
				}
			});
			setIssueFields(cloneIssueFields);
		}
		// DO NOT put 'issueFields' into dependency array, setIssueFields is called inside useEffect
		// it will lead to infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueMeta, callApi]);

	useEffect(() => {
		if (selectedProjectId && selectedIssueTypeId) {
			getIssueMeta(selectedProjectId, selectedIssueTypeId);
		}
	}, [selectedProjectId, selectedIssueTypeId]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleFields = (fields): any => {
		const currentFieldsKeys = Object.keys(issueMeta || {});
		const currentFields = pick(fields, currentFieldsKeys);

		const res = {};
		Object.entries(currentFields).forEach(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([key, value]: [string, FieldType & { value: { item: any } }]) => {
				if ([INPUT_TYPES.STRING, INPUT_TYPES.TEXTAREA].includes(value.type as INPUT_TYPES.STRING)) {
					res[key] = value.value;
					return;
				}
				if (value.type === INPUT_TYPES.SELECT) {
					if (value.isMultiple) {
						const items = value.value as OptionType[];
						if (value.schema?.items === INPUT_TYPES.STRING) {
							res[key] = items?.map((item) => item.value);
							return;
						}
						res[key] = items?.map((item) => ({ name: item.label }));
						return;
					}
					const currentSelection = (value.value as OptionType)?.value;
					res[key] = currentSelection ? { id: currentSelection } : null;
				}
			}
		);

		return res;
	};

	const createIssue = async (): Promise<void> => {
		// when changing Issue Type - fields, that are not relevant to this Issue Type,
		// will be sent and request will fail, so it's needed to pick only needed fields
		const fields = Object.fromEntries(issueFields);
		const data = handleFields(fields);

		const [response, error] = await asyncHandler<ICreatedIssue>(
			JiraApi.createIssue({ fields: data }),
			'Cannot publish item to JIRA'
		);
		if (response) {
			if (fields.attachment) {
				await asyncHandler<IAttachmentAdded>(
					JiraApi.addAttachmentToIssue(response.id, fields.attachment.value),
					'Cannot add attachment to issue.'
				);
			}
			JiraApi.openJiraTicket(response.key);
			closeModal();
		}
		if (error) {
			// eslint-disable-next-line no-alert
			alert('Cannot publish item to JIRA. Please try again');
		}
	};

	const fields = useMemo((): FieldType[] => {
		const fieldsArray = Array.from(issueFields.values());
		const orderedFields = [];
		const restFields = [];
		// set fields in predefined order
		fieldsArray.forEach((field) => {
			const index = fieldsOrder.findIndex((fieldName) => fieldName === field.name);
			if (index !== -1) {
				orderedFields[index] = field;
			} else {
				restFields.push(field);
			}
		});
		// use compact to remove empty items in the array
		return [...compact(orderedFields), ...restFields];
	}, [issueFields]);

	const isPublishButtonDisabled = useMemo(() => {
		return !Array.from(issueFields.values())
			.filter((field) => field.required)
			.every((field) => !!field.value);
	}, [issueFields]);

	const buttons = [
		{
			title: 'OK',
			onClick: () => {
				logger.info('Error popup closed on OK click');
				closeModal();
			}
		}
	];

	if (isLoading) {
		return (
			<SiteScannerPopup title="Loading" isCentered isOpen={isLoading} popupWidth="auto">
				<div className="spinner-wrapper">
					<EvSpinner />
				</div>
			</SiteScannerPopup>
		);
	}

	if (!isLoggedIn && alertModalOpen) {
		return (
			<SiteScannerPopup
				title={alertPopupTitle}
				buttons={buttons}
				isControlled
				isCentered
				isOpen={alertModalOpen}
				className="error-popup"
				onClose={closeModal}
				popupWidth="auto"
			>
				<div className="alert-popup">Jira is not connected</div>
			</SiteScannerPopup>
		);
	}
	return (
		<EvIssueFormModal
			appElement={SITE_SCANNER_APP_ID}
			title={issueFormTitle}
			isOpen={isOpen}
			toggleIssueModal={toggleJiraModal}
			onSubmit={createIssue}
			fields={fields}
			onChange={setIssueFieldsData}
			isSubmitDisabled={isPublishButtonDisabled}
			popupWidth="100%"
		/>
	);
};

export default JiraIssueCreator;
